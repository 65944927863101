import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import CategoryTree from "@/components/Category/Tree.vue";
import { FadeTransition } from "vue2-transitions";
export default {
  data() {
    return {
      catalog_items: [],
      categories: [],
      popups: {}
    };
  },
  components: {
    FadeTransition,
    CategoryTree
  },
  methods: {
    openPopup(id) {
      this.catalog_items.forEach(item => {
        if (item.c_id == id) {
          item.show_popup = true;
        } else {
          item.show_popup = false;
        }
      });
    },
    closePopups() {
      this.catalog_items.forEach(item => {
        if (item.show_popup) {
          item.show_popup = false;
        }
      });
    },
    getCategory(id) {
      return this.categories.find(item => item.c_id == id);
    },
    requireIcon(c_id) {
      try {
        return require(`@/assets/images/icons/${this.$route.params.mp}/${c_id}.svg`);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  },
  async mounted() {
    let data = await this.$store.dispatch("category/getCategoryList", {
      c_id: 0,
      mp: this.$route.params.mp
    });
    this.catalog_items = data.map(item => ({
      ...item,
      show_popup: false
    }));
    this.categories = this.catalog_items.map(item => ({
      ...item,
      checked: false,
      show: true
    }));
  }
};