var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('svg', _vm._g(_vm._b({
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, 'svg', _vm.$attrs, false), _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M4.19531 8.8618L5.13798 9.80446L7.99998 6.94246L10.862 9.80446L11.8046 8.8618L7.99998 5.05713L4.19531 8.8618Z",
      "fill": "currentColor"
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };