import Checkbox from "@/components/Checkbox";
import ArrowIcon from "./ArrowIcon";
export default {
  components: {
    Checkbox,
    ArrowIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    root: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      validate: v => ["links", "checkboxes"].includes(v),
      default: "links"
    },
    getLink: {
      type: Function,
      default: null
    }
  },
  methods: {
    onChange(v) {
      if (v) {
        this.$emit("check", this.item);
      } else {
        this.$emit("uncheck", this.item);
      }
    }
  }
};