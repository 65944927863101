var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('tree-view', {
    attrs: {
      "nodes": _vm.root,
      "get-link": _vm.getLink
    },
    on: {
      "check": _vm.onCheck,
      "uncheck": _vm.onUncheck,
      "show": _vm.onShow,
      "hide": _vm.onHide
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };