var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "treeview-branch",
    class: {
      root: _vm.root
    }
  }, [_c('div', {
    staticClass: "treeview-item"
  }, [_vm.mode === 'checkboxes' ? _c('checkbox', {
    attrs: {
      "value": _vm.item.checked,
      "title": _vm.item.title,
      "rounded": ""
    },
    on: {
      "change": function ($event) {
        return _vm.onChange($event);
      }
    }
  }) : _vm._e(), _c('router-link', {
    staticClass: "treeview-link",
    attrs: {
      "to": _vm.getLink(_vm.item)
    }
  }, [_vm._v(_vm._s(_vm.item.title))]), !_vm.item.child || _vm.item.child && _vm.item.child.length > 0 ? _c('arrow-icon', {
    staticClass: "treeview-item__arrow",
    class: {
      'treeview-item__close': !_vm.item.show
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$emit(_vm.item.show ? 'hide' : 'show', _vm.item);
      }
    }
  }) : _vm._e()], 1), _vm._l(_vm.item.child, function (i) {
    return [_vm.item.show ? _c('tree-node', {
      key: i.id,
      attrs: {
        "mode": _vm.mode,
        "item": i,
        "get-link": _vm.getLink
      },
      on: {
        "check": function ($event) {
          return _vm.$emit('check', $event);
        },
        "uncheck": function ($event) {
          return _vm.$emit('uncheck', $event);
        },
        "show": function ($event) {
          return _vm.$emit('show', $event);
        },
        "hide": function ($event) {
          return _vm.$emit('hide', $event);
        }
      }
    }) : _vm._e()];
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };