var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('vue-custom-scrollbar', {
    staticClass: "treeview",
    attrs: {
      "settings": _vm.scrollSettings
    }
  }, [_c('tree-node', {
    attrs: {
      "get-link": _vm.getLink,
      "item": _vm.nodes,
      "root": ""
    },
    on: {
      "check": function ($event) {
        return _vm.$emit('check', $event);
      },
      "uncheck": function ($event) {
        return _vm.$emit('uncheck', $event);
      },
      "show": function ($event) {
        return _vm.$emit('show', $event);
      },
      "hide": function ($event) {
        return _vm.$emit('hide', $event);
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };