var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "catalog"
  }, _vm._l(_vm.catalog_items, function (cat) {
    return _c('div', {
      key: cat.c_id,
      staticClass: "catalog-item",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.openPopup(cat.c_id);
        }
      }
    }, [_c('div', {
      staticClass: "catalog-item-button"
    }, [_c('img', {
      attrs: {
        "src": _vm.requireIcon(cat.c_id),
        "width": "20",
        "height": "20"
      }
    }), _c('div', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(cat.c_name) + " "), _c('fade-transition', [cat.show_popup ? _c('div', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside",
        value: _vm.closePopups,
        expression: "closePopups"
      }],
      staticClass: "catalog-item-popup"
    }, [_c('category-tree', {
      attrs: {
        "category": _vm.getCategory(cat.c_id)
      },
      model: {
        value: _vm.popups[cat.c_id],
        callback: function ($$v) {
          _vm.$set(_vm.popups, cat.c_id, $$v);
        },
        expression: "popups[cat.c_id]"
      }
    })], 1) : _vm._e()])], 1)])]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };